import { pick } from 'lodash';
import { RequestConstants, RequestActions } from '../../request';
import { OrganizationSettingsConstants } from '../constants';
import { actions as unsavedChangesActions } from '../../unsaved-changes';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';
import * as flashActions from '../../flash/actionCreators';
import {
  organizationTimeslotsSelectors,
} from '../../timeslots/selectors';

const {
  organizationDefaultTimeslotsSelector,
  organizationTimeslotDaysSelector,
} = organizationTimeslotsSelectors;

const timeslotToApi = timeslot => (
  pick(timeslot, ['dayOfWeekNum', 'name', 'hour', 'baseTimeslotUuid', 'minute', 'dayOffset', 'isClosed'])
);

const recordTimeslotsUpdatedMetric = () => (
  metricsPublisher.recordMetric(
    TrailMetricsDirectory.page.Organization.ORGANIZATION_TIMESLOT_CHANGED
  ));

const flashOrganizationUpdated = () => flashActions.notice('organization.organizationUpdated');
const flashOrganizationUpdateFailed = () => flashActions.alert('organization.organizationUpdatedError');

const updateTimeslots = (defaultTimeslots, orgTimeslots) => RequestActions.request({
  url: OrganizationSettingsConstants.saveOrganizationTimeslotsRoute(
    window.current_user.organization_id
  ),
  key: OrganizationSettingsConstants.SAVE_TIMESLOTS_KEY,
  method: RequestConstants.PUT,
  errorMessage: OrganizationSettingsConstants.messages.SAVE_TIMESLOTS_FAILED,
  options: {
    body: JSON.stringify({
      default_timeslots: defaultTimeslots.map(timeslotToApi),
      organization_timeslots: orgTimeslots.map(timeslotToApi),
    }),
  },
});

export const OrganizationSettingsActions = {
  getSettings() {
    return RequestActions.request({
      url: OrganizationSettingsConstants.getOrUpdateSettingsRoute(
        window.current_user.organization_id
      ),
      key: OrganizationSettingsConstants.GET_SETTINGS_KEY,
      method: RequestConstants.GET,
      errorMessage: OrganizationSettingsConstants.messages.GET_SETTINGS_FAILED,
    });
  },
  saveOrganizationTimeslots: () => async (dispatch, getState) => {
    const state = getState();

    const defaultTimeslots = organizationDefaultTimeslotsSelector(state);
    const orgTimeslots = organizationTimeslotDaysSelector(state);

    const { success } = await dispatch(updateTimeslots(defaultTimeslots, orgTimeslots));

    if (success) {
      dispatch(unsavedChangesActions.cleared());
      await recordTimeslotsUpdatedMetric();
      dispatch(flashOrganizationUpdated());
      return Promise.resolve(true);
    }

    dispatch(flashOrganizationUpdateFailed());
    return Promise.resolve(false);
  },
  updateSettings(formattedUpdateObject) {
    return RequestActions.request({
      url: OrganizationSettingsConstants.getOrUpdateSettingsRoute(
        window.current_user.organization_id
      ),
      key: OrganizationSettingsConstants.UPDATE_SETTINGS_KEY,
      method: RequestConstants.PUT,
      errorMessage: OrganizationSettingsConstants.messages.UPDATE_SETTINGS_FAILED,
      content: {
        updateObject: formattedUpdateObject,
      },
      options: {
        body: JSON.stringify({
          settings: formattedUpdateObject,
        }),
      },
    });
  },
};
