import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { calcStartMinutes } from './utils';

const TimeslotMarker = ({ children, top }) => (
  <div data-test='timeslot.marker' className='business-hours__timeslot_marker' style={ { top } }>
    <div className='business-hours__timeslot_marker-line-container'>
      <div className='business-hours__timeslot_marker-line' />
      <div>
        <div className='business-hours__timeslot_marker-sign'>
          {children}
        </div>
      </div>
    </div>
  </div>
);

TimeslotMarker.propTypes = {
  children: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
};

const intlPathForIndex = {
  1: {
    intlPath: 'timeslots.editor.blockLabels.open',
    useEnd: false,
  },
  6: {
    intlPath: 'timeslots.editor.blockLabels.closed',
    useEnd: true,
  },
};

const TimeslotMarkers = ({ timeslots }) => {
  const { formatMessage } = useIntl();
  const startDayMinutes = calcStartMinutes(timeslots[0]);
  return (
    <div
      className='business-hours__timeslot-markers'
      style={ { marginTop: timeslots[0].minute } }
    >
      {timeslots.reduce((acc, timeslot, index) => {
        const { intlPath, useEnd } = intlPathForIndex[index] || {};
        if (!intlPath) return acc;

        const startTop = calcStartMinutes(timeslot) - startDayMinutes;
        const top = useEnd ? startTop + timeslot.duration : startTop;
        return [
          ...acc,
          <TimeslotMarker key={ timeslot.baseTimeslotUuid } top={ top }>
            { formatMessage({ id: intlPath }) }
          </TimeslotMarker>,
        ];
      }, [])}
    </div>
  );
};

TimeslotMarkers.propTypes = {
  timeslots: PropTypes.arrayOf(PropTypes.shape({
    duration: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    dayOffset: PropTypes.number,
    baseTimeslotUuid: PropTypes.string,
  })).isRequired,
};

export default TimeslotMarkers;
