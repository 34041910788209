import { UserRole } from '../application/UserRole';

export const CUSTOM_ROLE_LIST_URL = '/api/custom_roles';
export const FETCH_CUSTOM_ROLES_KEY = '@custom_roles fetch_roles';
export const updateRoleUrl = id => `/api/custom_roles/${id}`;
export const deleteRoleUrl = id => `/api/custom_roles/${id}`;

const {
  MANAGE_PERMITTED_TASKS,
  VIEW_OTHER_CUSTOM_ROLE_TASKS,
} = UserRole.permissionTypes;

export const tooltipMessages = {
  [MANAGE_PERMITTED_TASKS]: 'site_task_management',
  [VIEW_OTHER_CUSTOM_ROLE_TASKS]: 'role_task_management',
};
