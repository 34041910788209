import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import metricsPublisher, { TrailMetricsDirectory } from '../metrics';
import { TaskPlannerActions } from './actions';
import { TaskPlannerConstants } from './constants';
import CheckBox from '../common/forms/CheckBox';
import ToolTip from '../common/ToolTip';
import { tagSorter } from '../tags';
import TaskTemplateCounts from './TaskTemplateCounts';
import { getCurrentUserRole } from '../application/UserRole';
import CreatorFilter from './CreatorFilter';

export class TaskPlannerSidebar extends Component {
  onTrailSelect(isChecked, locationId) {
    const metric = isChecked ?
      TrailMetricsDirectory.page.TaskPlanner.TRAIL_FILTER_DESELECTED :
      TrailMetricsDirectory.page.TaskPlanner.TRAIL_FILTER_SELECTED;
    metricsPublisher.recordMetric(metric, { trailId: locationId });

    this.props.actions.taskPlanner.filterByTrail(locationId);
  }

  onGroupSelect(isChecked, timelineTemplateId) {
    const metric = isChecked ?
      TrailMetricsDirectory.page.TaskPlanner.GROUP_FILTER_DESELECTED :
      TrailMetricsDirectory.page.TaskPlanner.GROUP_FILTER_SELECTED;
    metricsPublisher.recordMetric(metric, { groupId: timelineTemplateId });

    this.props.actions.taskPlanner.filterByGroup(timelineTemplateId);
  }

  onTagSelect(isChecked, tagId) {
    const metricPage = TaskPlannerConstants.metricsDirectoryPage(this.props.singleLocation);
    const metric = isChecked ? metricPage.TAG_FILTER_DESELECTED : metricPage.TAG_FILTER_SELECTED;
    metricsPublisher.recordMetric(metric, { tagId: tagId.toString() });

    this.props.actions.taskPlanner.filterByTag(tagId);
  }

  onClearTrailsFilter = () => {
    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.TaskPlanner.TRAIL_FILTERS_CLEARED
    );

    this.props.actions.taskPlanner.clearFilterByTrail();
  };

  onClearGroupsFilter = () => {
    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.TaskPlanner.GROUP_FILTERS_CLEARED
    );

    this.props.actions.taskPlanner.clearFilterByGroup();
  };

  onClearTagFilter = () => {
    metricsPublisher.recordMetric(
      TaskPlannerConstants.metricsDirectoryPage(this.props.singleLocation).TAG_FILTERS_CLEARED
    );

    this.props.actions.taskPlanner.clearFilterByTag();
  };

  groupsHintHoverOrClick = (interactionType) => {
    metricsPublisher.recordMetric(
      TrailMetricsDirectory.page.TaskPlanner.GROUP_FILTER_TOOLTIP_VIEWED,
      { interactionType }
    );
  };

  tagsHintHoverOrClick = (interactionType) => {
    metricsPublisher.recordMetric(
      TaskPlannerConstants.metricsDirectoryPage(this.props.singleLocation)
        .TAG_FILTER_TOOLTIP_VIEWED,
      { interactionType }
    );
  };

  renderTrailsFilter() {
    if (this.props.singleLocation) {
      return null;
    }

    const renderedOptions = this.props.locations.map((location) => {
      const isChecked = this.props.filters[TaskPlannerConstants.FILTER_BY_TRAIL]
        .indexOf(location.id) > -1;

      const filterClasses = classNames({
        'showtime-layout-admin-management__filter-item': true,
        'showtime-layout-admin-management__filter-item--trail': true,
        'showtime-layout-admin-management__filter-item--task-planner': true,
        'showtime-layout-admin-management__filter-item--task-planner-selected': isChecked,
      });

      return (
        <div
          className={ filterClasses }
          key={ `trail-filter-${location.id}` }
          data-test='trail-filters'
        >
          <CheckBox
            checkboxClasses='showtime-checkbox--small'
            label={ location.name }
            secondaryLabel={ location.task_templates_count }
            checked={ isChecked }
            onChange={ () => this.onTrailSelect(isChecked, location.id) }
          />
        </div>
      );
    }, this);

    return (
      <div className='showtime-layout-admin-management__filter-group-inner-scroll'>
        {renderedOptions}
      </div>
    );
  }

  renderTagsFilter() {
    const sortedTags = tagSorter.sort(this.props.tags);
    const renderedOptions = sortedTags.map((tag) => {
      const isChecked = this.props.filters[TaskPlannerConstants.FILTER_BY_TAG]
        .indexOf(tag.id) > -1;

      const filterClasses = classNames({
        'showtime-layout-admin-management__filter-item': true,
        'showtime-layout-admin-management__filter-item--task-planner': true,
        'showtime-layout-admin-management__filter-item--task-planner-selected': isChecked,
      });

      return (
        <div
          className={ filterClasses }
          key={ `tag-filter-${tag.id}` }
          data-test='tag-filters'
        >
          <CheckBox
            checkboxClasses='showtime-checkbox--small'
            label={ tag.label }
            checked={ isChecked }
            onChange={ () => this.onTagSelect(isChecked, tag.id) }
          />
        </div>
      );
    }, this);

    const className = classNames({
      'showtime-layout-admin-management__filter-group-inner-scroll': !this.props.isLibraryPlanner,
    });

    return (
      <div className={ className }>
        {renderedOptions}
      </div>
    );
  }

  renderGroupsFilter() {
    if (this.props.singleLocation) {
      return null;
    }
    const renderedOptions = this.props.timelineTemplates.map((timelineTemplate) => {
      const isChecked = this.props.filters[TaskPlannerConstants.FILTER_BY_GROUP]
        .indexOf(timelineTemplate.id) > -1;

      const filterClasses = classNames({
        'showtime-layout-admin-management__filter-item': true,
        'showtime-layout-admin-management__filter-item--task-planner': true,
        'showtime-layout-admin-management__filter-item--task-planner-selected': isChecked,
      });

      return (
        <div
          className={ filterClasses }
          key={ `group-filter-${timelineTemplate.id}` }
          data-test='group-filters'
        >
          <CheckBox
            checkboxClasses='showtime-checkbox--small'
            label={ timelineTemplate.name }
            checked={ isChecked }
            onChange={ () => this.onGroupSelect(isChecked, timelineTemplate.id) }
          />
        </div>
      );
    }, this);

    return (
      <div className='showtime-layout-admin-management__filter-group-inner-scroll'>
        {renderedOptions}
      </div>
    );
  }

  renderTrailsSection(clearTrailLinkClasses) {
    return (
      <div
        className='showtime-layout-admin-management__filter-group'
        ref='trailsSection'
        data-test='sidebar-trail-section'
      >
        <div
          className='showtime-layout-admin-management__filter-group-header'
        >
          <h2
            className='showtime-layout-admin-management_filter-title'
          >
            {this.props.intl.formatMessage({ id: 'taskplanner.sites' })}
            <ToolTip
              classes={ ['showtime-layout-admin-management__filters-help', 'hint--bottom-right'] }
              message={ this.props.intl.formatMessage({ id: 'taskplanner.trailsFilterHint' }) }
            />
          </h2>
        </div>
        <div className='showtime-layout-admin-management__filter-group-inner'>
          {this.renderTrailsFilter()}
        </div>
        <div className='showtime-layout-admin-management__filter-group-clear'>
          <a
            onClick={ this.onClearTrailsFilter }
            data-test='clear-trails-filter'
            className={ clearTrailLinkClasses }
          >
            {this.props.intl.formatMessage({ id: 'taskplanner.filterClear' })}
          </a>
        </div>
      </div>
    );
  }

  renderGroupsSection(clearGroupLinkClasses) {
    return (
      <div
        className='showtime-layout-admin-management__filter-group'
        ref='groupsSection'
        data-test='sidebar-groups-section'
      >
        <div className='showtime-layout-admin-management__filter-group-header'>
          <h2 className='showtime-layout-admin-management_filter-title'>
            {this.props.intl.formatMessage({ id: 'taskplanner.groupsFilterTitle' })}
            <ToolTip
              onInteraction={ this.groupsHintHoverOrClick }
              classes={ ['showtime-layout-admin-management__filters-help', 'hint--bottom-right'] }
              message={ this.props.intl.formatMessage({ id: 'taskplanner.groupsFilterHint' }) }
            />
          </h2>
        </div>
        <div className='showtime-layout-admin-management__filter-group-inner'>
          {this.renderGroupsFilter()}
        </div>
        <div className='showtime-layout-admin-management__filter-group-clear'>
          <a
            onClick={ this.onClearGroupsFilter }
            data-test='clear-groups-filter'
            className={ clearGroupLinkClasses }
          >
            {this.props.intl.formatMessage({ id: 'taskplanner.filterClear' })}
          </a>
        </div>
      </div>
    );
  }

  renderTagsSection(clearTagLinkClasses) {
    return (
      <div
        className='showtime-layout-admin-management__filter-group'
        ref='tagsSection'
        data-test='sidebar-tags-section'
      >
        <div className='showtime-layout-admin-management__filter-group-header'>
          <h2 className='showtime-layout-admin-management_filter-title'>
            {this.props.intl.formatMessage({ id: 'taskplanner.tagsFilterTitle' })}
            <ToolTip
              onInteraction={ this.tagsHintHoverOrClick }
              classes={ ['showtime-layout-admin-management__filters-help', 'hint--bottom-right'] }
              message={ this.props.intl.formatMessage({ id: 'taskplanner.tagsFilterHint' }) }
            />
          </h2>
          { !this.props.singleLocation && this.props.onManageTags && (
          <a
            data-test='tagsFilterEdit'
            onClick={ this.props.onManageTags }
            className='showtime-link showtime-link--default'
          >
            {this.props.intl.formatMessage({ id: 'taskplanner.tagsFilterEdit' })}
          </a>
          )}
        </div>
        <div className='showtime-layout-admin-management__filter-group-inner'>
          {this.renderTagsFilter()}
        </div>
        <div className='showtime-layout-admin-management__filter-group-clear'>
          <a
            onClick={ this.onClearTagFilter }
            data-test='clear-tags-filter'
            className={ clearTagLinkClasses }
          >
            {this.props.intl.formatMessage({ id: 'taskplanner.filterClear' })}
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { features } = window.config;
    const {
      singleLocation,
      locations,
      timelineTemplates,
      tags,
    } = this.props;

    const showTrails = features.multiSite && !singleLocation && Boolean(locations.length);
    const showGroups = features.areas && !singleLocation && Boolean(timelineTemplates.length);
    const showTags = features.tags && Boolean(tags.length);

    const showTaskTemplateCounts = _.get(window.config, 'features.taskTemplateLibrary') && !singleLocation;

    if (!showTrails && !showGroups && !showTags) {
      return null;
    }

    const trailFilters = this.props.filters[TaskPlannerConstants.FILTER_BY_TRAIL];
    const groupFilters = this.props.filters[TaskPlannerConstants.FILTER_BY_GROUP];
    const tagFilters = this.props.filters[TaskPlannerConstants.FILTER_BY_TAG];
    const creatorFilters = this.props.filters[TaskPlannerConstants.FILTER_BY_CREATOR];
    const showFilterByCreator = getCurrentUserRole().isOrgAdminOrAbove() ||
      getCurrentUserRole().hasCustomRole();

    const baseClasses = {
      'showtime-link': true,
      'showtime-link--default': true,
    };

    const clearTrailLinkClasses = classNames(_.extend(
      baseClasses,
      { 'is-visible': !!trailFilters.length }
    ));

    const clearGroupLinkClasses = classNames(_.extend(
      baseClasses,
      { 'is-visible': !!groupFilters.length }
    ));

    const clearTagLinkClasses = classNames(_.extend(
      baseClasses,
      { 'is-visible': !!tagFilters.length }
    ));

    return (
      <div data-test='task-planner-sidebar'>
        <div className='showtime-layout-admin-management__filters'>
          {showTaskTemplateCounts && (
            <div className='showtime-layout-admin-management__filter-group'>
              <TaskTemplateCounts
                intl={ this.props.intl }
                singleLocation={ singleLocation }
                filteredTaskTemplates={ this.props.taskPlanner.filteredTaskTemplates }
                taskTemplates={ this.props.taskPlanner.taskTemplates }
              />
            </div>
          )}
          { getCurrentUserRole().isOrgAdminOrAbove() &&
          showTrails && this.renderTrailsSection(clearTrailLinkClasses) }
          { getCurrentUserRole().isOrgAdminOrAbove() &&
          showGroups && this.renderGroupsSection(clearGroupLinkClasses) }
          { getCurrentUserRole().isOrgAdminOrAbove() &&
          showTags && this.renderTagsSection(clearTagLinkClasses) }
          { showFilterByCreator && (
          <CreatorFilter
            creatorIds={ creatorFilters }
            filterByCreator={ this.props.actions.taskPlanner.filterByCreator }
            clearFilterByCreator={ this.props.actions.taskPlanner.clearFilterByCreator }
          />
          )}
        </div>
      </div>
    );
  }
}

TaskPlannerSidebar.propTypes = {
  onManageTags: PropTypes.func,
  locations: PropTypes.array.isRequired,
  timelineTemplates: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  singleLocation: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    taskPlanner: PropTypes.object,
  }).isRequired,
  taskPlanner: PropTypes.object.isRequired,
  isLibraryPlanner: PropTypes.bool,
};
TaskPlannerSidebar.defaultProps = {
  onManageTags: null,
  isLibraryPlanner: false,
};

const mapDispatchToProps = dispatch => ({
  actions: {
    taskPlanner: bindActionCreators(TaskPlannerActions, dispatch),
  },
});

export default injectIntl(connect(null, mapDispatchToProps)(TaskPlannerSidebar));
