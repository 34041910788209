import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import CheckBox from '../common/forms/CheckBox';
import { getCurrentUserRole } from '../application/UserRole';
import { CREATOR_OPTIONS } from './constants/taskPlannerConstants';

const CreatorsFilterList = ({ creatorIds, onCreatorSelect }) => {
  const { formatMessage } = useIntl();

  const currentUserRole = getCurrentUserRole();
  const canViewOtherRoles = currentUserRole.isPermissionEnabled('view_other_custom_role_tasks');

  const creatorOptions = [
    { id: CREATOR_OPTIONS.ME, name: formatMessage({ id: 'taskplanner.creatorOptions.me' }) },
    { id: CREATOR_OPTIONS.ORGANIZATION_ADMINS, name: formatMessage({ id: 'taskplanner.creatorOptions.organizationAdmins' }) },
    ...(canViewOtherRoles || currentUserRole.isOrgAdminOrAbove()
      ? [{ id: CREATOR_OPTIONS.OTHER_ROLES, name: formatMessage({ id: 'taskplanner.creatorOptions.otherRoles' }) }]
      : []),
  ];

  return creatorOptions.map((creator) => {
    const isChecked = creatorIds.includes(creator.id);

    const filterClasses = classNames({
      'showtime-layout-admin-management__filter-item': true,
      'showtime-layout-admin-management__filter-item--trail': true,
      'showtime-layout-admin-management__filter-item--task-planner': true,
      'showtime-layout-admin-management__filter-item--task-planner-selected': isChecked,
    });

    return (
      <div className={ filterClasses } key={ `creator-filter-${creator.id}` } data-test='creator-filters'>
        <CheckBox
          checkboxClasses='showtime-checkbox--small'
          label={ creator.name }
          checked={ isChecked }
          onChange={ () => onCreatorSelect(creator.id) }
        />
      </div>
    );
  });
};

CreatorsFilterList.propTypes = {
  creatorIds: PropTypes.array.isRequired,
  onCreatorSelect: PropTypes.func.isRequired,
};

export default CreatorsFilterList;
