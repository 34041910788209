import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ToolTip from '../common/ToolTip';
import CreatorsFilterList from './CreatorFilterList';

const CreatorFilter = ({ creatorIds, filterByCreator }) => {
  const { formatMessage } = useIntl();

  return (
    <div className='showtime-layout-admin-management__filter-group' data-test='sidebar-creator-section'>
      <div className='showtime-layout-admin-management__filter-group-header'>
        <h2 className='showtime-layout-admin-management_filter-title'>
          {formatMessage({ id: 'taskplanner.creators' })}
          <ToolTip
            classes={ [
              'showtime-layout-admin-management__filters-help',
              'hint--bottom-right  hint--bottom-right-creator',
            ] }
            message={ formatMessage({ id: 'taskplanner.creatorsFilterHint' }) }
          />
        </h2>
      </div>
      <div className='showtime-layout-admin-management__filter-group-inner'>
        <CreatorsFilterList
          creatorIds={ creatorIds }
          onCreatorSelect={ filterByCreator }
        />
      </div>
    </div>
  );
};

CreatorFilter.propTypes = {
  filterByCreator: PropTypes.func.isRequired,
  creatorIds: PropTypes.array.isRequired,
};

export default CreatorFilter;
