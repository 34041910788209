import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { findIndex, last, get } from 'lodash';
import moment from 'moment-timezone';

import { isTimeslotMatch } from './utils';
import Button from '../../common/Button';
import { ConfirmationMenu } from '../../common/menu/ConfirmationMenu';

const RestoreButton = ({ onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      className='business-hours__closed-timeslot-button'
      onClick={ onClick }
      dataTest='timeslot.restore'
      link
    >
      { formatMessage({ id: 'timeslots.closed.restore' }) }
    </Button>
  );
};

RestoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const ClosedTimeslot = ({ label, onRestore, readOnly }) => (
  <div className='business-hours__closed-timeslot'>
    <div className='business-hours__closed-timeslot-name' data-test='timeslot.closed.label'>
      {label}
    </div>

    { !readOnly && <RestoreButton onClick={ onRestore } /> }
  </div>
);

ClosedTimeslot.propTypes = {
  label: PropTypes.string.isRequired,
  onRestore: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

const ClosedHeader = () => {
  const { formatMessage } = useIntl();

  return (
    <div className='business-hours__closed-header'>
      <div className='business-hours__closed-header-title'>
        { formatMessage({ id: 'timeslots.closed.header.title' }) }
      </div>
      <div className='business-hours__closed-header-description'>
        { formatMessage({ id: 'timeslots.closed.header.description' }) }
      </div>
    </div>
  );
};

const RestoreConfirmation = ({
  onConfirm, onCancel, timeslotName, priorTimeslotName, duration,
}) => {
  const { formatMessage } = useIntl();
  const momentDuration = moment.duration(duration, 'minutes');

  const description = formatMessage(
    { id: 'timeslots.closed.restoreConfirmation.description' },
    { priorTimeslotName, time: momentDuration.humanize() }
  );
  return (
    <ConfirmationMenu
      align='top'
      title={ formatMessage({ id: 'timeslots.closed.restoreConfirmation.title' }, { timeslotName }) }
      abort={ formatMessage({ id: 'timeslots.closed.restoreConfirmation.cancel' }) }
      confirm={ formatMessage({ id: 'timeslots.closed.restoreConfirmation.confirm' }) }
      description={ description }
      onConfirm={ onConfirm }
      onAbort={ onCancel }
      dataTest='timeslot.restore.confirmation'
      showMenu
      onClickOutside={ onCancel }
    />
  );
};

RestoreConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  timeslotName: PropTypes.string.isRequired,
  priorTimeslotName: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
};

const ClosedTimeslots = ({
  timeslots, visibleTimeslots, onTimeslotsUpdate, readOnly,
}) => {
  const closedVisibleTimeslots = visibleTimeslots
    .filter(timeslot => timeslot.isClosed);

  const [timeslotToRestore, setTimeslotToRestore] = useState(null);

  if (!closedVisibleTimeslots.length) return null;

  const closeModal = () => setTimeslotToRestore(null);

  const timeslotToRestoreIndex = findIndex(timeslots, { baseTimeslotUuid: get(timeslotToRestore, 'baseTimeslotUuid') });
  const priorTimeslotIndex = timeslotToRestoreIndex - 1;
  const priorTimeslot = timeslots[priorTimeslotIndex] || last(timeslots);

  const handleRestoreTimeslot = () => {
    const updatedTimeslots = timeslots.map(timeslot => (
      isTimeslotMatch(timeslot, timeslotToRestore)
        ? { ...timeslot, isClosed: false }
        : timeslot
    ));
    closeModal();
    onTimeslotsUpdate(updatedTimeslots);
  };

  return (
    <div className='business-hours__closed' data-test='timeslots.closed'>
      <ClosedHeader />
      { Boolean(timeslotToRestore && priorTimeslot) && (
        <RestoreConfirmation
          onConfirm={ handleRestoreTimeslot }
          onCancel={ closeModal }
          timeslotName={ timeslotToRestore.name }
          priorTimeslotName={ priorTimeslot.name }
          duration={ timeslotToRestore.duration }
        />
      )}
      <div className='business-hours__closed-list'>
        {closedVisibleTimeslots
          .map(timeslot => (
            <ClosedTimeslot
              label={ timeslot.name }
              key={ timeslot.baseTimeslotUuid }
              readOnly={ readOnly }
              onRestore={ () => setTimeslotToRestore(timeslot) }
            />
          ))}
      </div>
    </div>
  );
};

const timeslotPropType = PropTypes.shape({
  name: PropTypes.string,
  isClosed: PropTypes.bool,
  dayOfWeekNum: PropTypes.number,
  dayOffset: PropTypes.number,
  baseTimeslotUuid: PropTypes.string,
});

ClosedTimeslots.propTypes = {
  visibleTimeslots: PropTypes.arrayOf(timeslotPropType).isRequired,
  timeslots: PropTypes.arrayOf(timeslotPropType).isRequired,
  onTimeslotsUpdate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

ClosedTimeslots.defaultProps = {
  readOnly: false,
};

export default ClosedTimeslots;
