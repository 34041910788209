import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  findIndex, last, filter, find,
} from 'lodash';

import SVGIcon from '../../common/SVGIcon';
import { START, END, DAY_MINUTES } from './constants';
import { calcStartMinutes, calcUpdatedTimeslots, timeslotWithDuration } from './utils';
import TimeSelect from './TimeSelect';
import CloseTimeslot from './CloseTimeslot';
import Button from '../../common/Button';

const TimeslotFormTitle = () => (
  <div className='business-hours__editor-title'>
    <SVGIcon
      iconName='mini/ui/ui-16px-2_time-clock'
      classes='showtime-icon showtime-icon--margin-right'
    />
    <span className='showtime-utility-font-body-small-bold'>
      { useIntl().formatMessage({ id: 'timeslots.editor.title' }) }
    </span>
  </div>
);

const TimeslotName = ({ children }) => (
  <div
    className='business-hours__editor-timeslot-title'
  >
    {children}
  </div>
);

TimeslotName.propTypes = {
  children: PropTypes.node.isRequired,
};

const ZeroMinuteWarning = () => (
  <div className='business-hours__editor-warning' data-test='timeslot.warning'>
    <SVGIcon
      iconName='mini/ui/ui-16px-2_alert-circle-error'
      classes='business-hours__editor-warning-svg'
    />
    <div className='showtime-utility-font-body-small'>
      { useIntl().formatMessage({ id: 'timeslots.editor.zeroMinutes' }) }
    </div>
  </div>
);

const ClockIcon = () => (
  <SVGIcon
    iconName='mini/ui/ui-16px-2_time-clock'
    classes='showtime-icon showtime-icon--margin-right'
  />
);

const CustomHours = ({
  onReset, viewDefaultHoursButton, children, readOnly,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div className='business-hours__info' data-test='timeslots.custom'>
      <ClockIcon />
      <div>
        <div className='business-hours__info-title'>
          { formatMessage({ id: 'timeslots.editor.customHours.title' }) }
        </div>
        <div className='showtime-utility-font-body-small'>
          { children && (
            <div className='business-hours__info-paragraph'>{ children }</div>
          ) }
          <div className='business-hours__info-paragraph'>
            { formatMessage({ id: 'timeslots.editor.customHours.setDefaultDescription' }) }
          </div>
          { viewDefaultHoursButton }
          { !readOnly && (
            <Button default onClick={ onReset } dataTest='timeslots.reset'>
              { formatMessage({ id: 'timeslots.editor.customHours.reset' }) }
            </Button>
          ) }
        </div>
      </div>
    </div>
  );
};

CustomHours.propTypes = {
  onReset: PropTypes.func.isRequired,
  viewDefaultHoursButton: PropTypes.node,
  children: PropTypes.node,
  readOnly: PropTypes.bool.isRequired,
};

CustomHours.defaultProps = {
  viewDefaultHoursButton: null,
  children: null,
};

const DefaultHours = ({ viewDefaultHoursButton, children }) => (
  <div className='business-hours__info' data-test='timeslots.default'>
    <ClockIcon />
    <div>
      <div className='business-hours__info-title'>
        { useIntl().formatMessage({ id: 'timeslots.editor.defaultHours.title' }) }
      </div>
      <div className='showtime-utility-font-body-small'>
        <div className='business-hours__info-paragraph'>
          { children }
        </div>
        { viewDefaultHoursButton}
      </div>
    </div>
  </div>
);

DefaultHours.propTypes = {
  viewDefaultHoursButton: PropTypes.node,
  children: PropTypes.node,
};

DefaultHours.defaultProps = {
  viewDefaultHoursButton: null,
  children: null,
};

const resetTimeslotDay = (
  timeslots, defaultTimeslots, dayOfWeekNum
) => timeslots.map((timeslot) => {
  if (timeslot.dayOfWeekNum !== dayOfWeekNum) return timeslot;

  return find(defaultTimeslots, {
    baseTimeslotUuid: timeslot.baseTimeslotUuid,
    dayOfWeekNum,
  });
});

const TimeslotForm = ({
  focusedTimeslot: timeslot,
  timeslots,
  onTimeslotsUpdate,
  onTimeslotClose,
  dayHasOverride,
  defaultTimeslots,
  canClose,
  dayOfWeekNum,
  viewingDefaultMessage,
  viewingCustomMessage,
  viewDefaultHoursButton,
  readOnly,
}) => {
  const { formatMessage } = useIntl();

  const onReset = () => onTimeslotsUpdate(
    resetTimeslotDay(timeslots, defaultTimeslots, dayOfWeekNum)
  );

  if (!timeslot) {
    return (
      dayHasOverride
        ? (
          <CustomHours { ...{ onReset, viewDefaultHoursButton, readOnly } }>
            { viewingCustomMessage }
          </CustomHours>
        ) : <DefaultHours { ...{ viewDefaultHoursButton } }>{ viewingDefaultMessage }</DefaultHours>
    );
  }

  const onChange = (key, value) => onTimeslotsUpdate(
    calcUpdatedTimeslots(timeslots, timeslot, key, value));

  const { duration } = timeslotWithDuration(timeslots)(timeslot, findIndex(timeslots, timeslot));
  const lastTimeslotOfDay = last(filter(timeslots, { dayOfWeekNum: timeslot.dayOfWeekNum }));
  const timeslotIsLastOfTheDay = lastTimeslotOfDay.baseTimeslotUuid === timeslot.baseTimeslotUuid;

  return (
    <div className='business-hours__editor' data-test='timeslot.form'>
      <TimeslotFormTitle />
      <div className='business-hours__editor-timeslot'>
        <TimeslotName>{ timeslot.name }</TimeslotName>

        <TimeSelect
          label={ formatMessage({ id: 'timeslots.editor.start' }) }
          value={ calcStartMinutes(timeslot) }
          onChange={ e => onChange(START, e.target.value) }
          dayOfWeekNum={ timeslot.dayOfWeekNum }
          id='start'
        />

        <TimeSelect
          label={ formatMessage({ id: 'timeslots.editor.end' }) }
          value={ calcStartMinutes(timeslot) + duration }
          dayOfWeekNum={ timeslot.dayOfWeekNum }
          min={ timeslotIsLastOfTheDay && DAY_MINUTES }
          onChange={ e => onChange(END, e.target.value) }
          id='end'
        />
        { !duration && <ZeroMinuteWarning /> }
        { canClose && (
          <CloseTimeslot
            { ...{ timeslot, timeslots, onTimeslotClose } }
          />
        )}
      </div>
    </div>
  );
};

const timeslotPropType = PropTypes.shape({
  duration: PropTypes.number,
  name: PropTypes.string,
  hour: PropTypes.number,
  minute: PropTypes.number,
  isClosed: PropTypes.bool,
  dayOffset: PropTypes.number,
  dayOfWeekNum: PropTypes.number,
  baseTimeslotUuid: PropTypes.string,
});

TimeslotForm.propTypes = {
  focusedTimeslot: timeslotPropType,
  timeslots: PropTypes.arrayOf(timeslotPropType).isRequired,
  onTimeslotsUpdate: PropTypes.func.isRequired,
  canClose: PropTypes.bool,
  onTimeslotClose: PropTypes.func.isRequired,
  dayHasOverride: PropTypes.bool,
  defaultTimeslots: PropTypes.arrayOf(timeslotPropType).isRequired,
  dayOfWeekNum: PropTypes.number.isRequired,
  viewingDefaultMessage: PropTypes.string.isRequired,
  viewingCustomMessage: PropTypes.string.isRequired,
  viewDefaultHoursButton: PropTypes.node.isRequired,
  readOnly: PropTypes.bool,
};

TimeslotForm.defaultProps = {
  canClose: true,
  focusedTimeslot: null,
  dayHasOverride: false,
  readOnly: false,
};

export default TimeslotForm;
