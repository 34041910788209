import _ from 'lodash';
import moment from 'moment-timezone';
import { TaskWizardConstants, ReportConstants } from '../constants';
import TaskScheduleConstants from '../../schedule/constants';
import { ScheduleActions } from './scheduleActions';
import setupGuideActions from '../../setup-guide/actions/setupGuideActions';
import AttachmentConstants from '../../attachments/constants';
import { TaskTemplateConstants } from '../../task/constants';
import metricsPublisher, { TrailMetricsDirectory } from '../../metrics';
import { RequestActions, RequestConstants } from '../../request';
import { TaskPlannerActions } from '../../task-planner/actions';
import { routeTo } from '../../router/actionCreators';
import { actions as unsavedChangesActions } from '../../unsaved-changes';
import { RouterConstants } from '../../router';
import {
  TaskWidgetSelectors,
  TaskScheduleSelectors,
  assignedUserIdsToPersistSelector,
  permissionLevelToPersistSelector,
  taskAssignedCustomRoleIdsSelector,
  editPermissionTypesSelector,
  taskRequiresPinSelector,
} from '../selectors';
import { getWidgetEditedSettings } from '../../widget/selectors';
import { EditWidgetActions } from '../../widget/actions';
import { cleanSubtasks } from '../taskWizardHelper';
import setupGuideSelectors from '../../setup-guide/selectors/setupGuideSelectors';
import { getTaskFlaggableValueSelector, getTaskPriorityValueSelector } from '../selectors/taskAssignmentSelectors';

const { updateWidgetConfig, createWidgetConfig } = EditWidgetActions;

const handleSaveSuccess = taskTemplateId => (dispatch) => {
  dispatch(unsavedChangesActions.cleared());

  dispatch(TaskPlannerActions.getTaskPlan());
  dispatch(TaskPlannerActions.highlightTaskTemplate(taskTemplateId));
  dispatch(routeTo(RouterConstants.buildPreviewTaskUrl(taskTemplateId)));
};

const createOrUpdateWidgetConfig = (
  organizationId, isLibraryTask
) => async (dispatch, getState) => {
  const state = getState();
  const stagedWidgetChanges = getWidgetEditedSettings(state);

  if (!_.get(stagedWidgetChanges, 'settings')) return Promise.resolve({ success: true });

  const { settings, id: widgetId } = TaskWidgetSelectors.editedTaskWidgetConfig(state);

  const isNewWidget = !_.isNumber(widgetId);
  const createWidget = isNewWidget || isLibraryTask;
  const widgetRequest = createWidget
    ? createWidgetConfig(organizationId, settings)
    : updateWidgetConfig(organizationId, widgetId, settings);

  const widgetResponse = await dispatch(widgetRequest);

  const widgetUpdateFailed = widgetResponse && !widgetResponse.success;
  if (widgetUpdateFailed) return Promise.resolve({ success: false });

  const createdWidget = createWidget && {
    id: widgetResponse.success.body.id,
    widgetDataType: widgetResponse.success.body.widget_data_type,
  };

  const originalWidgetColumns = _.get(
    TaskWidgetSelectors.selectedIntegrationSelector(state),
    'settings.public.columns',
    []
  );
  const newWidgetColumns = _.get(settings, 'public.columns', []);
  const columnLogicIsEmpty = column => !_.isEmpty(_.get(column, 'cellSettings.logic'));
  const originalWidgetHasLogic = _.some(originalWidgetColumns, columnLogicIsEmpty);
  const newWidgetHasLogic = _.some(newWidgetColumns, columnLogicIsEmpty);
  const logicAdded = !originalWidgetHasLogic && newWidgetHasLogic;
  if (logicAdded) {
    await metricsPublisher.recordMetric(TrailMetricsDirectory.page.TaskReports.LOGIC_ADDED);
  }

  return Promise.resolve({
    success: true,
    body: widgetResponse.success.body,
    createdWidget,
  });
};

const fetchTemplateData = (url, taskTitleFormat, isCopying = false) => dispatch => dispatch(
  RequestActions.request({
    url,
    key: TaskWizardConstants.GET_TASK_KEY,
    method: RequestConstants.GET,
    errorMessage: 'newTask.errors.cannotGetTask',
    content: { taskTitleFormat, isCopying },
  })
).then(({ success }) => {
  if (success) {
    const { schedules = [], template_type: templateType } = success.body;
    if (!TaskTemplateConstants.templatesWithoutSchedule.includes(templateType)) {
      schedules.forEach((schedule) => {
        const nextInstanceLink = _.get(schedule, 'relationships.next_instances.links.related');
        if (nextInstanceLink) {
          dispatch(
            ScheduleActions
              .fetchNextInstancesForARelatedSchedule(schedule.id, nextInstanceLink)
          );
        }
      });

      return Promise.resolve();
    }
  }
  return Promise.resolve();
});

const handleTaskWrite = taskRequest => async (dispatch) => {
  const { success: taskSaveSuccess } = await dispatch(taskRequest);
  if (!taskSaveSuccess) return Promise.resolve();

  return dispatch(handleSaveSuccess(taskSaveSuccess.body.id));
};

const { OFF, COMPLETED, COMPLETED_WITH_EXCEPTIONS } = ReportConstants.selectMode;

export const TaskWizardActions = {
  returnToPlanner() {
    return (dispatch) => {
      dispatch(unsavedChangesActions.cleared());

      dispatch(TaskPlannerActions.getTaskPlan());
      dispatch(routeTo(RouterConstants.BASE_URL));
    };
  },

  getScheduleTypeFromTask(task) {
    if (!task.schedules || !task.schedules.length
          || task.template_type === TaskTemplateConstants.templateType.AUTOMATED) {
      return TaskScheduleConstants.FIXED;
    }

    const schedule = task.schedules[0];

    if (schedule.first_instance_start_date !== schedule.first_instance_end_date) {
      return TaskScheduleConstants.ONGOING;
    }

    const start = schedule.duration.start_time || schedule.duration.start_timeslot;
    const end = schedule.duration.end_time || schedule.duration.end_timeslot;

    if (start === end) {
      return TaskScheduleConstants.FIXED;
    }

    return TaskScheduleConstants.ONGOING;
  },

  recordMetrics(task, schedule, editing = false, added = {}) {
    const taskEvent = {
      name: task.name,
      type: task.template_type,
    };

    const metrics = TrailMetricsDirectory.page.TaskWizard;

    const taskMetric = editing ? metrics.TASK_EDITED : metrics.TASK_CREATED;

    metricsPublisher.recordMetric(taskMetric, taskEvent);

    if (TaskTemplateConstants.templatesWithoutSchedule.includes(task.template_type)) {
      return;
    }

    const scheduleEvent = {
      type: TaskWizardActions.getScheduleTypeFromTask(task),
    };

    const scheduleMetric = editing ? metrics.SCHEDULE_EDITED : metrics.SCHEDULE_CREATED;

    metricsPublisher.recordMetric(scheduleMetric, scheduleEvent);

    const locationsChangedInEdit = editing && added.locations;
    const locationAssignmentOccured = _.size(task.locations) && (
      !editing || locationsChangedInEdit
    );

    if (locationAssignmentOccured) {
      metricsPublisher.recordMetric(metrics.ASSIGN_TO_TRAIL_CONFIRMED);
    }

    const scheduleCount = Object.keys(schedule.content).length;
    const originalScheduleCount = Object.keys(schedule.originalContent).length;
    const multipleSchedulesAdded = scheduleCount > originalScheduleCount;
    const multipleSchedulesRemoved = scheduleCount < originalScheduleCount;

    if (multipleSchedulesAdded) {
      metricsPublisher.recordMetric(
        metrics.MULTIPLE_SCHEDULES_ADDED,
        { editing, scheduleCount, originalScheduleCount }
      );
    } else if (multipleSchedulesRemoved) {
      metricsPublisher.recordMetric(
        metrics.MULTIPLE_SCHEDULES_REMOVED,
        { editing, scheduleCount, originalScheduleCount }
      );
    }
  },

  createTask: (
    task, taskStatus, organizationId, copiedTaskTemplateId,
    libraryTaskTemplateId
  ) => async (dispatch, getState) => {
    dispatch(TaskWizardActions.beginSaving());
    const isLibraryTask = _.isNumber(libraryTaskTemplateId);
    const widgetRequest = createOrUpdateWidgetConfig(organizationId, isLibraryTask);
    const widgetResponse = await dispatch(widgetRequest);
    if (!widgetResponse.success) return Promise.resolve();

    const taskBody = TaskWizardActions.buildTaskBody(
      task,
      taskStatus,
      copiedTaskTemplateId,
      widgetResponse.createdWidget,
      getState()
    );

    taskBody.start_date = taskBody.start_date || moment().format('ddd DD MMMM YYYY HH:mm');
    TaskWizardActions.recordMetrics(taskBody, task.schedule);

    const createTaskRequest = RequestActions.request({
      url: TaskWizardConstants.CREATE_TASK_ROUTE,
      key: TaskWizardConstants.CREATE_TASK_KEY,
      method: RequestConstants.POST,
      errorMessage: 'newTask.errors.default',
      successMessage: 'newTask.success.created',
      options: {
        body: JSON.stringify(taskBody),
      },
      content: {
        isLibraryTask,
      },
    });

    const createResponse = await dispatch(handleTaskWrite(createTaskRequest));

    const isTaskStepComplete = setupGuideSelectors.isTasksStepCompleteSelector(getState());
    if (!isTaskStepComplete) {
      dispatch(setupGuideActions.getSetupGuideProgress());
    }

    return Promise.resolve(createResponse);
  },

  beginSaving: () => ({ type: TaskWizardConstants.BEGIN_SAVING }),

  editTask: (
    taskTemplateId, task, taskStatus, organizationId
  ) => async (dispatch, getState) => {
    dispatch(TaskWizardActions.beginSaving());
    const widgetResponse = await dispatch(createOrUpdateWidgetConfig(organizationId));
    if (!widgetResponse.success) return Promise.resolve();

    const taskBody = TaskWizardActions.buildTaskBody(
      task,
      taskStatus,
      null,
      widgetResponse.createdWidget,
      getState()
    );

    const {
      trails: {
        content: { value: editedLocations = [] } = {},
        originalContent: { value: originalLocations = [] } = {},
      },
    } = task;

    const added = {
      locations: Boolean(_.difference(editedLocations, originalLocations).length),
    };

    const editingTaskTemplate = true;
    TaskWizardActions.recordMetrics(taskBody, task.schedule, editingTaskTemplate, added);

    const editTaskRequest = RequestActions.request({
      url: TaskWizardConstants.getOrUpdateTaskRoute(taskTemplateId),
      key: TaskWizardConstants.EDIT_TASK_KEY,
      method: RequestConstants.PUT,
      errorMessage: 'newTask.errors.defaultEdit',
      successMessage: 'newTask.success.edited',
      options: {
        body: JSON.stringify(taskBody),
      },
    });

    return dispatch(handleTaskWrite(editTaskRequest));
  },

  getTask: (taskTemplateId, { taskTitleFormat = null } = {}) => dispatch => dispatch(
    fetchTemplateData(
      TaskWizardConstants.getOrUpdateTaskRoute(taskTemplateId),
      taskTitleFormat
    )
  ),

  getCopiedTemplateData: (taskTemplateId, taskTitleFormat) => dispatch => dispatch(
    fetchTemplateData(
      TaskWizardConstants.getLibraryTemplateTaskRoute(taskTemplateId),
      taskTitleFormat,
      TaskTemplateConstants.COPYING
    )
  ),

  buildTaskBody(task, status, copiedTaskTemplateId, createdWidget, state) {
    const scheduleFieldMappings = TaskScheduleSelectors
      .scheduleFieldsForRequestSelector(state);
    const taskBody = {
      ...TaskWizardConstants.DEFAULT_PARAMS,
      ...scheduleFieldMappings,
    };

    const attachments = this._buildAttachments(task.attachments);
    const { selectMode } = task.report.content;
    const subscriptions = _.values(task.report.content.taskCompletionUserSubscriptions)
      .map(({ id, isSubscribed }) => ({
        user_id: id,
        task_completed_enabled: selectMode === COMPLETED && isSubscribed,
        task_completed_with_exceptions_enabled: (
          selectMode === COMPLETED_WITH_EXCEPTIONS && isSubscribed),
      }));
    const notifyAllUsers = task.report.content.notifyAllUsers
      ? task.report.content.selectMode : OFF;

    const { notifyOnAssign } = task.report.content;

    createdWidget = createdWidget || {};
    const availableIntegrations = task.widget.content.availableIntegrations || [];

    const existingWidget = _.find(availableIntegrations, { id: task.widget.content.widget });

    const widgetId = createdWidget.id || _.get(existingWidget, 'id');
    const integration = (
      createdWidget.widgetDataType || _.get(existingWidget, 'widget_data_type', null)
    );

    const permissionLevel = permissionLevelToPersistSelector(state);
    const assignedUserIds = assignedUserIdsToPersistSelector(state);
    const assignedCustomRoleIds = taskAssignedCustomRoleIdsSelector(state);
    const flaggableValue = getTaskFlaggableValueSelector(state);
    const priorityValue = getTaskPriorityValueSelector(state);

    return _.extend(taskBody, {
      name: task.title.content.value,
      notes: task.helpText.content.value,
      subtasks_mandatory: task.checkList.content.isMandatory,
      subtasks: cleanSubtasks(task.checkList.content.checkList),
      links: attachments.links,
      files: attachments.files,
      widget_config_id: widgetId,
      integration,
      subscriptions,
      notify_all_users: notifyAllUsers,
      notify_on_assign: notifyOnAssign,
      status,
      locations:
        TaskTemplateConstants.templatesWithoutSchedule.includes(task.template.content.value)
          ? []
          : task.trails.content.value,
      tags: task.tags.content.tags,
      permission_level: permissionLevel,
      assigned_user_ids: assignedUserIds,
      assigned_custom_role_ids: assignedCustomRoleIds,
      locked: task.template.content.isLocked,
      completion_rules: { comments: task.mandatoryComments },
      flaggable: flaggableValue,
      priority: priorityValue,
      copiedTaskTemplateId,
      edit_permission: editPermissionTypesSelector(state),
      warn_on_early: TaskScheduleSelectors.earlyTaskWarningSelector(state),
      requires_pin: taskRequiresPinSelector(state),
    });
  },

  selectSection: activeSection => ({
    type: TaskWizardConstants.SELECT_ACTIVE_SECTION,
    activeSection,
  }),

  _buildAttachments(attachments) {
    const { State } = AttachmentConstants;

    const uploadedAttachmentsOnly = function (attachment) {
      return (attachment.state === State.SAVED)
        || (attachment.state === State.EDITING);
    };

    const desiredLinkFormat = function (attachment) {
      return {
        name: attachment.name,
        url: attachment.url,
        type: attachment.type,
      };
    };

    const desiredFileFormat = function (attachment) {
      return {
        name: attachment.name,
        file_name: attachment.fileName,
        filestack_handle: attachment.filestackHandle,
        mime_type: attachment.mimeType,
        file_size: attachment.fileSize,
        s3_key: attachment.s3Key,
        type: attachment.type,
      };
    };

    const desiredFormat = function (formattedAttachments, attachment) {
      switch (attachment.type) {
        case 'link':
          formattedAttachments.links.push(desiredLinkFormat(attachment));
          break;
        case 'file':
          if (uploadedAttachmentsOnly(attachment)) {
            formattedAttachments.files.push(desiredFileFormat(attachment));
          }
          break;
        default:
          break;
      }
      return formattedAttachments;
    };

    return attachments
      .reduce(desiredFormat, { links: [], files: [] });
  },
};
