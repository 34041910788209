import _ from 'lodash';
import { createSelector } from 'reselect';
import { organizationUsersSelector } from '../../users/selectors';
import { ReportConstants } from '../constants';
import { UserRole } from '../../application/UserRole';

const getReportContent = store => store.newTask.report.content || {};

const getTaskCompletionUserSubscriptions = store => getReportContent(store)
  .taskCompletionUserSubscriptions;

export const getUsersForTaskCompletionSubscriptions = (store) => {
  const userSubscriptions = getTaskCompletionUserSubscriptions(store);
  const users = _.values(organizationUsersSelector(store));

  const subscribedManagingUsers = users.reduce((accu, user) => {
    if (new UserRole(user.role).isEnduser()) return accu;

    const isSubscribed = _.get(userSubscriptions, [user.id, 'isSubscribed'], false);
    return [...accu, { ...user, isSubscribed }];
  }, []);

  return _.orderBy(subscribedManagingUsers, ['role', 'name'], ['desc', 'asc']);
};

const notifyAllUsersSelector = createSelector(getReportContent, content => content.notifyAllUsers);

const selectModeSelector = createSelector(getReportContent, content => content.selectMode);

export const selectModeForAllCompletedTasksSelector = createSelector(
  selectModeSelector,
  selectMode => (selectMode === ReportConstants.selectMode.COMPLETED)
);

export const selectModeForAllCompletedExceptionTasksSelector = createSelector(
  selectModeSelector,
  selectMode => (selectMode === ReportConstants.selectMode.COMPLETED_WITH_EXCEPTIONS)
);

export const getTaskCompletionAllSubscribed = createSelector(
  notifyAllUsersSelector,
  getUsersForTaskCompletionSubscriptions,
  (notifyAllUsers, userSubscriptions) => {
    const hasUsersAndTheyAreAllSubscribed = _.size(userSubscriptions) && _.every(userSubscriptions, 'isSubscribed');
    return (notifyAllUsers || hasUsersAndTheyAreAllSubscribed);
  }
);

export const notifyOnAssignSelector = createSelector(
  getReportContent,
  content => content.notifyOnAssign
);
