import _ from 'lodash';
import { TaskCheckListConstants, TaskWizardConstants } from '../constants';
import { TaskConstants } from '../../task/constants';
import { RequestConstants } from '../../request';
import { isValidSubtaskGroupName } from '../../common/subtaskValidator';

export const taskCheckListReducer = (function () {
  function generateId(type) {
    const prefix = type === TaskCheckListConstants.TYPE.GROUP
      ? TaskCheckListConstants.ITEM_PREFIXES.GROUP : TaskCheckListConstants.ITEM_PREFIXES.NEW;
    return _.uniqueId(prefix);
  }

  function buildItem(type, name, id = null) {
    return {
      id: id || generateId(type),
      name: name || '',
      type,
    };
  }

  function formatChecklist(checklist) {
    const checklistGroups = _.groupBy(checklist, 'group_name');
    const checklistWithGroupHeadings = Object.keys(checklistGroups).map(groupName => (
      [
        ...(isValidSubtaskGroupName(groupName) ?
          [buildItem(TaskCheckListConstants.TYPE.GROUP, groupName)] : []),
        ...checklistGroups[groupName].map(
          st => buildItem(TaskCheckListConstants.TYPE.ITEM, st.name, st.id)
        ),
      ]
    ));

    return _.flatten(checklistWithGroupHeadings);
  }

  function loadChecklist(state, action) {
    const formattedChecklist = formatChecklist(action.content.subtask_templates);

    const content = {
      checkList: formattedChecklist,
      isMandatory: !!action.content.subtasks_mandatory,
    };

    return _.extend({}, state, {
      expandedItemId: null,
      content,
      isExpanded: Boolean(formattedChecklist.length),
    });
  }

  function setIsMandatory(state, action) {
    const { isMandatory } = action;

    return _.extend({}, state, {
      content: {
        ...state.content,
        isMandatory,
      },
    });
  }

  function addItem(state, action) {
    const content = _.cloneDeep(state.content);
    const items = content.checkList;
    const newItem = buildItem(action.itemType);
    items.splice(action.position, 0, newItem);

    return {
      ...state,
      expandedItemId: newItem.id,
      content,
    };
  }

  function removeItem(state, action) {
    const content = _.cloneDeep(state.content);
    const items = content.checkList;
    items.splice(action.position, 1);

    return {
      ...state,
      content,
    };
  }

  function sortItems(state, action) {
    const { itemIdToNewPosition } = action;
    const items = _.sortBy(state.content.checkList, item => itemIdToNewPosition[item.id]);

    return {
      ...state,
      content: {
        ...state.content,
        checkList: items,
      },
    };
  }

  function parsePastedText(text) {
    return text.split('\n').filter(item => Boolean(item.trim()));
  }

  function constructNewItemsFromNames(itemNames) {
    return itemNames.map(name => buildItem(TaskCheckListConstants.TYPE.ITEM, name));
  }

  function pasteTextAtSelection(name, pastedText, selectionStart, selectionEnd) {
    const nameArray = name.split('');
    nameArray.splice(selectionStart, selectionEnd - selectionStart, pastedText);
    return nameArray.join('');
  }

  function handlePasteIntoItemName(state, action) {
    const pastedItemNames = parsePastedText(action.pastedText);
    if (pastedItemNames.length < 1) {
      return state;
    }
    let { expandedItemId } = state;
    const content = _.cloneDeep(state.content);
    const items = content.checkList;
    const itemReceivingPaste = items[action.position];
    itemReceivingPaste.name = pasteTextAtSelection(
      itemReceivingPaste.name, pastedItemNames.shift(), action.selectionStart, action.selectionEnd
    );

    if (pastedItemNames.length > 0) {
      const newItems = constructNewItemsFromNames(pastedItemNames);
      items.splice(action.position + 1, 0, ...newItems);
      expandedItemId = _.last(newItems).id;
    }

    return {
      ...state,
      expandedItemId,
      content,
    };
  }

  function shouldFilterItem(item) {
    return item.name === '';
  }

  function changeItemName(state, action) {
    let newItem;
    let { expandedItemId } = state;
    const content = _.cloneDeep(state.content);
    const items = content.checkList;
    const updatedItemIndex = _.findIndex(items, action.item);
    const names = action.value.split('\n');
    const updatedItem = items[updatedItemIndex];
    const [updatedName] = names;
    updatedItem.name = updatedName;

    names.slice(1).forEach((name, index) => {
      newItem = buildItem(TaskCheckListConstants.TYPE.ITEM, name);
      items.splice(updatedItemIndex + index + 1, 0, newItem);
      expandedItemId = newItem.id;
    });

    if (shouldFilterItem(updatedItem) && expandedItemId !== updatedItem.id) {
      items.splice(updatedItemIndex, 1);
    }

    return {
      ...state,
      expandedItemId,
      content,
    };
  }

  function handleExpansion(state, expandedItemId) {
    const content = _.cloneDeep(state.content);
    let items = content.checkList;
    const previousItem = _.find(items, { id: state.expandedItemId });
    if (previousItem && shouldFilterItem(previousItem)) {
      items = _.reject(items, previousItem);
    }

    return {
      ...state,
      expandedItemId,
      content: {
        ...content,
        checkList: items,
      },
    };
  }

  function expandItem(state, action) {
    return handleExpansion(state, action.itemId);
  }

  function unexpandItem(state) {
    return handleExpansion(state, null);
  }

  function switchItemType(type) {
    return type === TaskCheckListConstants.TYPE.ITEM ?
      TaskCheckListConstants.TYPE.GROUP :
      TaskCheckListConstants.TYPE.ITEM;
  }

  function convertItem(state, action) {
    return {
      ...state,
      content: {
        ...state.content,
        checkList: state.content.checkList.map((item) => {
          if (item.id !== action.item.id) return item;

          const newType = switchItemType(item.type);
          let { originalItemId } = item;
          let newId;
          if (newType === TaskCheckListConstants.TYPE.ITEM) {
            newId = originalItemId ? item.originalItemId : generateId(newType);
            return { ...item, type: newType, id: newId };
          }
          originalItemId ||= item.id;
          newId = generateId(newType);
          return {
            ...item, type: newType, id: newId, originalItemId,
          };
        }),
      },
    };
  }

  function expandChecklist(state) {
    const content = _.cloneDeep(state.content);
    const emptyItem = buildItem(TaskCheckListConstants.TYPE.ITEM, '');
    content.checkList = [emptyItem];

    return {
      ...state,
      expandedItemId: emptyItem.id,
      content,
      isExpanded: true,
    };
  }

  return function reducer(state, action) {
    const initialState = {
      expandedItemId: null,
      content: {
        checkList: [],
        isMandatory: true,
      },
      isExpanded: false,
    };
    const currState = state || _.extend({}, initialState);

    switch (action.type) {
      case RequestConstants.getLoadedActionType(TaskWizardConstants.GET_TASK_KEY):
        return loadChecklist(currState, action);
      case TaskCheckListConstants.CHANGE_IS_MANDATORY:
        return setIsMandatory(currState, action);
      case TaskCheckListConstants.ADD_ITEM:
        return addItem(currState, action);
      case TaskCheckListConstants.REMOVE_ITEM:
        return removeItem(currState, action);
      case TaskCheckListConstants.SORT_ITEMS:
        return sortItems(currState, action);
      case TaskCheckListConstants.PASTE_INTO_ITEM_NAME:
        return handlePasteIntoItemName(currState, action);
      case TaskCheckListConstants.CHANGE_ITEM_NAME:
        return changeItemName(currState, action);
      case TaskCheckListConstants.EXPAND_ITEM:
        return expandItem(currState, action);
      case TaskCheckListConstants.UNEXPAND_ITEM:
        return unexpandItem(currState);
      case TaskCheckListConstants.CONVERT_ITEM:
        return convertItem(currState, action);
      case TaskCheckListConstants.EXPAND:
        return expandChecklist(currState);
      case TaskConstants.RESET:
        return _.extend({}, initialState);
      default:
        return currState;
    }
  };
}());
