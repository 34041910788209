import moment from 'moment-timezone';
import { RequestConstants } from '../../request';
import { requestStatuses } from '../../common/requestStatuses';
import { TimeslotConstants } from '../constants';
import { timeslotHelper } from '..';

export const timeslotReducer = (function () {
  const initialState = function () {
    return {
      defaultTimeslots: [],
      defaultTimeslotsLoaded: false,
      location: {
        entities: [],
        locationId: null,
        changed: false,
        requestStatus: requestStatuses.NOT_REQUESTED,
      },
      organization: {
        entities: {
          defaultTimeslots: [],
          timeslotOverrides: [],
          initialTimeslots: [],
        },
        requestStatus: requestStatuses.NOT_REQUESTED,
      },
    };
  };

  const createTimeBlock = function (timeslotAttributes) {
    return {
      hour: timeslotAttributes.hour,
      minute: timeslotAttributes.minute,
      fmt: moment(`${timeslotAttributes.hour}:${timeslotAttributes.minute}`, TimeslotConstants.TIME_FMT).format(TimeslotConstants.TIME_FMT),
      dayOffset: timeslotAttributes.dayOffset,
    };
  };

  const endTimeslotIsTheStartOfTomorrow = function (nextTimeslot, index, timeslots) {
    return index === timeslots.length - 1 && nextTimeslot === timeslots[0];
  };

  const mapTimeslotsWithEndTime = function (timeslot, index, timeslots) {
    const nextTimeslot = timeslots[index + 1] || timeslots[0];
    if (endTimeslotIsTheStartOfTomorrow(nextTimeslot, index, timeslots)) {
      nextTimeslot.attributes.dayOffset = timeslot.attributes.dayOffset;
    }
    return {
      type: timeslot.type,
      id: timeslot.id,
      name: timeslot.attributes.name,
      start: createTimeBlock(timeslot.attributes),
      end: createTimeBlock(nextTimeslot.attributes),
    };
  };

  const defaultTimeslots = function (timeslot) {
    return timeslot.type === TimeslotConstants.DEFAULT_TIMESLOTS;
  };

  const transformApiTimeslot = function (apiTimeslot) {
    return {
      id: apiTimeslot.id,
      name: apiTimeslot.attributes.name,
      start: createTimeBlock(apiTimeslot.attributes),
      type: apiTimeslot.type,
    };
  };

  const setTimeslots = function (state, action) {
    const newTimeslots = action.content.data
      .filter(defaultTimeslots)
      .sort((firstApiTimeslot, secondApiTimeslot) => {
        const firstTimeslot = transformApiTimeslot(firstApiTimeslot);
        const secondTimeslot = transformApiTimeslot(secondApiTimeslot);
        return timeslotHelper.sortTimeslotByStartTime(firstTimeslot, secondTimeslot);
      })
      .map(mapTimeslotsWithEndTime);

    return {
      defaultTimeslots: newTimeslots,
      defaultTimeslotsLoaded: true,
    };
  };

  const setLocationTimeslots = (state, action) => ({
    ...state,
    location: {
      id: action.content.locationId,
      entities: action.content.locationTimeslots,
      requestStatus: requestStatuses.LOADED,
    },
  });

  const flattenAttributes = ({ attributes, ...timeslot }) => ({
    ...timeslot,
    ...attributes,
    isClosed: attributes.closed,
  });

  const setOrgDefaultTimeslots = (state, action) => ({
    ...state,
    organization: {
      entities: {
        defaultTimeslots: action.content.data.reduce((accu, timeslot) => {
          if (timeslot.type !== TimeslotConstants.DEFAULT_TIMESLOTS) return accu;
          return [...accu, {
            ...flattenAttributes(timeslot),
            baseTimeslotUuid: timeslot.id,
          }];
        }, []),
        timeslotOverrides: action.content.data.reduce((accu, timeslot) => {
          if (timeslot.type === TimeslotConstants.DEFAULT_TIMESLOTS) return accu;

          return [...accu, {
            ...flattenAttributes(timeslot),
            baseTimeslotUuid: timeslot.attributes.baseTimeslotId,
            dayOfWeekNum: timeslot.attributes.weekdayNumber,
          }];
        }, []),
        initialTimeslots: action.content.organizationTimeslots.map(
          ({ isClosed, ...timeslot }) => ({ ...timeslot, isClosed: !!isClosed })
        ),
      },
      requestStatus: requestStatuses.LOADED,
    },
  });

  const reducer = function (state = initialState(), action = {}) {
    switch (action.type) {
      case RequestConstants.getLoadedActionType(TimeslotConstants.TIMESLOTS_LOADED):
        return setTimeslots(state, action);
      case RequestConstants.getLoadedActionType(TimeslotConstants.FETCH_LOCATION_TIMESLOTS):
        return setLocationTimeslots(state, action);
      case RequestConstants.getLoadedActionType(TimeslotConstants.FETCH_ORGANIZATION_TIMESLOTS):
        return setOrgDefaultTimeslots(state, action);
      case TimeslotConstants.UPDATE_LOCATION_TIMESLOTS:
        return ({
          ...state,
          location: {
            ...state.location,
            changed: true,
            entities: action.timeslots,
          },
        });
      case TimeslotConstants.UPDATE_ORGANIZATION_TIMESLOTS:
        return ({
          ...state,
          organization: {
            ...state.organization,
            entities: {
              ...state.organization.entities,
              organizationTimeslots: action.timeslots,
            },
          },
        });
      case TimeslotConstants.UPDATE_DEFAULT_ORGANIZATION_TIMESLOTS:
        return ({
          ...state,
          organization: {
            ...state.organization,
            entities: {
              ...state.organization.entities,
              defaultTimeslots: action.timeslots,
            },
          },
        });
      case TimeslotConstants.UPDATE_ORG_TIMESLOT_OVERRIDES:
        return {
          ...state,
          organization: {
            ...state.organization,
            entities: {
              ...state.organization.entities,
              timeslotOverrides: action.timeslotOverrides,
            },
          },
        };
      default:
        return state;
    }
  };

  return reducer;
}());
