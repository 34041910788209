import { max, findIndex, toNumber } from 'lodash';
import {
  DAY_HOURS, DAY_MINUTES, HOUR_MINUTES, LAST_DAY_OF_WEEK_NUM, START,
} from './constants';
import { TimeslotConstants } from '../constants';

export const getHourWithDayOffset = ({ hour, dayOffset }) => (dayOffset * DAY_HOURS) + hour;

export const calcStartMinutes = ({ hour = 0, minute = 0, dayOffset = 0 } = {}
) => (getHourWithDayOffset({ hour, dayOffset }) * HOUR_MINUTES) + minute;

export const isTimeslotMatch = (timeslot, { dayOfWeekNum, baseTimeslotUuid }) => (
  timeslot.dayOfWeekNum === dayOfWeekNum && timeslot.baseTimeslotUuid === baseTimeslotUuid
);

export const timeslotWithDuration = timeslots => (timeslot, index) => {
  const nextTimeslot = timeslots[index + 1] || timeslots[0];
  const isTheNextBusinessDay = nextTimeslot.dayOfWeekNum !== timeslot.dayOfWeekNum;
  const onlyOneBusinessDay = timeslots.every(
    ({ dayOfWeekNum }) => dayOfWeekNum === timeslot.dayOfWeekNum);
  const nextTimeslotWrapsBusinessDays = isTheNextBusinessDay
  || (onlyOneBusinessDay && !timeslots[index + 1]);

  const nextTimeslotWithOffset = {
    ...nextTimeslot,
    dayOffset: max([
      timeslot.dayOffset,
      nextTimeslot.dayOffset,
      ...nextTimeslotWrapsBusinessDays ? [1] : [],
    ]),
  };

  const duration = calcStartMinutes(nextTimeslotWithOffset) - calcStartMinutes(timeslot);

  return { ...timeslot, duration };
};

export const mapTimeslotsDuration = timeslots => timeslots.map(timeslotWithDuration(timeslots));

const calcTimeslotUpdatesFromMinutes = (updateInMinutes) => {
  const hour = Math.floor((updateInMinutes % DAY_MINUTES) / HOUR_MINUTES);
  const minute = updateInMinutes % HOUR_MINUTES;
  const dayOffset = Math.floor(updateInMinutes / DAY_MINUTES);
  return { hour, minute, dayOffset };
};

const applyStartToTimeslot = (focusedTimeslot, focusedIndex, updateInMinutes) => (
  (timeslot, index) => {
    const timeslotUpdates = calcTimeslotUpdatesFromMinutes(updateInMinutes);
    if (index === focusedIndex) return { ...timeslot, ...timeslotUpdates };

    const partOfBusinessDay = timeslot.dayOfWeekNum === focusedTimeslot.dayOfWeekNum;
    const isBeforeFocused = index < focusedIndex;
    const isAfterFocused = !isBeforeFocused;
    const updateStartsEarlierThanTimeslotStart = updateInMinutes < calcStartMinutes(timeslot);
    const updateStartsLaterThanTimeslotStart = !updateStartsEarlierThanTimeslotStart;

    const canBeEditedAsSameDayTimeslot = partOfBusinessDay && ((
      isBeforeFocused && updateStartsEarlierThanTimeslotStart
    ) || (
      isAfterFocused && updateStartsLaterThanTimeslotStart
    ));

    const editingADefaultTimeslot = focusedTimeslot.type === TimeslotConstants.DEFAULT_TIMESLOTS;
    const isDayBeforeFocused = (timeslot.dayOfWeekNum + 1) === focusedTimeslot.dayOfWeekNum;
    const isDayAfterFocused = timeslot.dayOfWeekNum === (focusedTimeslot.dayOfWeekNum + 1);
    const isLastDayBeforeFocused = (
      timeslot.dayOfWeekNum === LAST_DAY_OF_WEEK_NUM
    ) && focusedTimeslot.dayOfWeekNum === 0;

    const isFirstDayAfterFocused = (
      timeslot.dayOfWeekNum === 0
    ) && focusedTimeslot.dayOfWeekNum === LAST_DAY_OF_WEEK_NUM;

    const isPartOfPriorBusinessDay = (
      isDayBeforeFocused || isLastDayBeforeFocused || editingADefaultTimeslot);
    const isPartOfNextBusinessDay = (
      isDayAfterFocused || isFirstDayAfterFocused || editingADefaultTimeslot);

    const timeslotIsPastMidnight = calcStartMinutes(timeslot) >= DAY_MINUTES;
    const updatedPastMidnight = updateInMinutes >= DAY_MINUTES;

    const canBeEditedAsPriorDayTimeslot = (
      isPartOfPriorBusinessDay && timeslotIsPastMidnight && (
        (updateInMinutes + DAY_MINUTES) < calcStartMinutes(timeslot)
      )
    );

    const canBeEditedAsNextDayTimeslot = (
      isPartOfNextBusinessDay && updatedPastMidnight && (
        (updateInMinutes - DAY_MINUTES) > calcStartMinutes(timeslot)
      )
    );

    const canBeEdited = (
      canBeEditedAsSameDayTimeslot ||
      canBeEditedAsPriorDayTimeslot || canBeEditedAsNextDayTimeslot);
    if (!canBeEdited) return timeslot;

    let { dayOffset } = timeslotUpdates;
    if (canBeEditedAsPriorDayTimeslot || canBeEditedAsNextDayTimeslot) {
      dayOffset = timeslot.dayOffset;
    }

    return {
      ...timeslot,
      ...timeslotUpdates,
      dayOffset,
    };
  });

const shouldApplyEndAsStart = (timeslot, index, focusedIndex, updateInMinutes) => {
  const isAfterFocused = index > focusedIndex;
  const isBeforeFocused = !isAfterFocused;
  const isJustAfterFocused = index === focusedIndex + 1;

  const timeslotStartsBeforeUpdate = calcStartMinutes(timeslot) < updateInMinutes;

  if (isAfterFocused && timeslotStartsBeforeUpdate) return true;

  if (!(isBeforeFocused || isJustAfterFocused)) return false;

  const timeslotStartsAfterUpdate = !timeslotStartsBeforeUpdate;
  return timeslotStartsAfterUpdate;
};

const applyEndToTimeslot = (focusedTimeslot, focusedIndex, updateInMinutes) => (
  (timeslot, index) => {
    const partOfBusinessDay = timeslot.dayOfWeekNum === focusedTimeslot.dayOfWeekNum;

    const canBeEditedAsSameDayTimeslot = partOfBusinessDay && (
      shouldApplyEndAsStart(timeslot, index, focusedIndex, updateInMinutes)
    );
    const timeslotUpdates = calcTimeslotUpdatesFromMinutes(updateInMinutes);
    if (canBeEditedAsSameDayTimeslot) return { ...timeslot, ...timeslotUpdates };

    const isDayAfterFocused = timeslot.dayOfWeekNum - 1 === focusedTimeslot.dayOfWeekNum;
    const isFirstDayAfterFocused = (
      timeslot.dayOfWeekNum === 0
    ) && focusedTimeslot.dayOfWeekNum === LAST_DAY_OF_WEEK_NUM;
    const editingADefaultTimeslot = focusedTimeslot.type === TimeslotConstants.DEFAULT_TIMESLOTS;
    const isPartOfNextBusinessDay = (
      isDayAfterFocused || isFirstDayAfterFocused || editingADefaultTimeslot);

    if (!isPartOfNextBusinessDay) return timeslot;

    if (updateInMinutes < DAY_MINUTES) return timeslot;

    const nextDayRolloverUpdate = updateInMinutes % DAY_MINUTES;
    const rolloverFocusedIndex = (isFirstDayAfterFocused || editingADefaultTimeslot)
      ? -1 : focusedIndex;
    const canBeEditedAsNextDayTimeslot = shouldApplyEndAsStart(
      timeslot, index, rolloverFocusedIndex, nextDayRolloverUpdate);

    if (!canBeEditedAsNextDayTimeslot) return timeslot;

    return {
      ...timeslot,
      ...timeslotUpdates,
      dayOffset: timeslot.dayOffset,
    };
  });

export const calcUpdatedTimeslots = (
  timeslots, focusedTimeslot, editType, updateInMinutes
) => {
  updateInMinutes = toNumber(updateInMinutes);
  const { dayOfWeekNum, baseTimeslotUuid } = focusedTimeslot;
  const focusedIndex = findIndex(timeslots, { dayOfWeekNum, baseTimeslotUuid });

  if (editType === START) {
    return timeslots.map(applyStartToTimeslot(focusedTimeslot, focusedIndex, updateInMinutes));
  }

  return timeslots.map(applyEndToTimeslot(focusedTimeslot, focusedIndex, updateInMinutes));
};
