import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SVGIcon from './SVGIcon';
import { ConfirmationMenu } from './menu';

class ConfirmableButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmation: false,
    };
  }

  toggleMenu = () => {
    this.setState(prevState => ({ showConfirmation: !prevState.showConfirmation }));
  };

  onConfirm = () => {
    this.toggleMenu();
    this.props.onConfirm();
  };

  onAbort = () => {
    this.toggleMenu();
    this.props.onAbort();
  };

  render() {
    const { dataTest, dataHint, ...menuProps } = this.props;

    return (
      <React.Fragment>
        <a
          { ...dataHint && { 'data-hint': dataHint } }
          { ...dataTest && { 'data-test': dataTest } }
          className={ this.props.buttonClasses }
          onClick={ this.toggleMenu }
        >
          {
            this.props.buttonIcon &&
            (
              <SVGIcon
                classes={ this.props.iconClasses }
                iconName={ this.props.buttonIcon }
              />
            )
          }
          { this.props.buttonLabel }
        </a>
        {
          this.state.showConfirmation &&
          (
            <ConfirmationMenu
              { ...{
                ...menuProps,
                onConfirm: this.onConfirm,
                onAbort: this.onAbort,
                onClickOutside: this.toggleMenu,
              }
              }
            />
          )
        }
      </React.Fragment>
    );
  }
}

ConfirmableButton.propTypes = {
  buttonLabel: PropTypes.string,
  buttonClasses: PropTypes.string,
  iconClasses: PropTypes.string,
  buttonIcon: PropTypes.string,
  dataTest: PropTypes.string,
  dataHint: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  abort: PropTypes.string,
  confirm: PropTypes.string,
  onConfirm: PropTypes.func,
  isDestructive: PropTypes.bool,
  onAbort: PropTypes.func,
};

ConfirmableButton.defaultProps = {
  buttonLabel: '',
  buttonClasses: '',
  iconClasses: '',
  buttonIcon: '',
  dataTest: undefined,
  dataHint: undefined,
  title: '',
  description: '',
  abort: '',
  confirm: '',
  onConfirm() {},
  isDestructive: true,
  onAbort() {},
};

export default ConfirmableButton;
